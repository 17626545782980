import React, {useState, useEffect, useCallback} from 'react'
import moment from 'moment-timezone';

import {Spinner} from '@material-tailwind/react'
import { debounce } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty, tip, contentLength, serviceFeeRate, padding, feeAddresses } from '../configs/constant';
import { Input } from '@material-tailwind/react'
import { formatAddress } from '../util/format-data'
import { registerPayment, getPaymentTx, getPaymentUtxos, getMintStatus, tokenLatest, registerToken } from '../util/api'
import { getAsciiSum } from '../util/format-data';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFee, getData, isValidTaprootAddress} from '../util/inscribe-util';
import FeeRateCard from '../components/FeeRateCard';
import mintImg from '../assets/imgs/mintimg.png';
import logonameImg from '../assets/imgs/logoname.png'; 
import { getAdminData, mintCount, mintRecommend, mintCancel, mintRegister, mintTotal, mintFail } from '../util/api';
import { fetchImageAsHex } from '../util/inscribe-util';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';

import { useNavigate, useLocation } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";

import { useWallet, useWallets } from '@wallet-standard/react';

export default function Test() {
  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const wallet = useSelector(state => state.wallet);

  const navigate = useNavigate();

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
 
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState('');
  const [amount, setAmount] = useState(1);
  const [maximum, setMaximum] = useState(10000);
  const [sum, setSum] = useState(0);
  const [deployLimit, setDeployLimit] = useState(21000);
  const [deployCount, setDeployCount] = useState(0);
  const [address, setAddress] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState('');
  const [customFee, setCustomFee] = useState(0);
  const [sliderValue, setSliderValue] = useState(2);
  const [show, setShow] = useState(false);
  const [inscriptionStatus, setInscriptionStatus] = useState(false);
  const [showMintForm, setShowMintForm] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0.7);
  const [paymentLimit, setPaymentLimit] = useState(0.7);
  const [deductPayment, setDeductPayment] = useState(0.1);
  const [timerText, setTimerText] = useState("00:00:00");
  const [referencedate, setReferencedate] = useState("2024-07-03");
  const [mintFlag, setMintFlag] = useState(true);
  const [referencetime, setReferencetime] = useState(0);

  const handleMint = async (privkey, receiverAddress, feerate, number, registerId) => {
    setLoading(true);

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    // let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    //let mimetype = "text/plain;charset=utf-8";
    let mimetype = "image/webp";
    let salts = "";
    let ids = [];

    for(let i = 0; i< 1; i++)
    {

      const hexText = await fetchImageAsHex("https://eyes4384.com/images/" + number + ".webp");
      // const hexText = "hello" + temp.number;
      console.log("--------------", hexText);
      ids.push(registerId);

      files.push({
        text: JSON.stringify(hexText),
        name: hexText,
        hex: hexText,
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    let recipientList = [];

    let totalInscriptionFee = 0;

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      let inscriptionFee = getInscriptionFee(data.length, feerate);
      console.log(`----i: ${i} -------InscriptionFee: ${inscriptionFee}`)

      totalInscriptionFee += inscriptionFee;

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );

      recipientList.push ({
        address: inscriptionAddress,
        amountSats: BigInt(inscriptionFee),
      })
    }
    
    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    totalInscriptionFee += royalty * amount;

    let addressList = [];
    let totalFee = calculateFee(1, amount + 1, feerate) + totalInscriptionFee;
    addressList.push(_fundingAddress);

    
    let transactionData
    let flag = false;
    for (let i = 0; i < 1; i++) {
      for(let j = 0; j< 3; j++) 
      {
        transactionData = await getMempoolUtxos(inscriptions[i].inscriptionAddress);
        if (transactionData.length >= 1){
          flag = true;
          break;
        }
        await waitSomeSeconds(2);
      }

      if (flag == false) {

      }
      else {
        await inscribe(inscriptions[i], transactionData[0].vout, transactionData[0].txid, transactionData[0].value, seckey, registerId, privkey, receiverAddress);
      }
    }

    setLoading(false);
  }

  const inscribe = async(inscription, vout, txid2, amt2, seckey, registerId, privkey, receiverAddress) => {
    let _toAddress;
    let _script;
    let toAddress = receiverAddress;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    await sleep(1000);

    if(_txid2.includes('descendant'))
    {
        // include_mempool = false;
        // inscribe(inscription, vout, txid2, amt2, seckey);
        return;
    }

    try {
      JSON.parse(_txid2);
    } catch (e) {
      console.log(_txid2);
      /// Save inscription data
      ///
      if (_txid2.toString().length == 64) {
        let body = {
          id : registerId,
          number : registerId - 1,
          address : receiverAddress,
          inscriptionId : _txid2 + "i0",
          privateKey : privkey
        }
        await mintRegister(body);
      }
    }
    
  }

  const handleCopy = (value, mode) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        if (mode == 1)
          toast.success("Name copied!");
        else
          toast.success("Address copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const addAmount = () => {
    let amountInt = parseInt(amount);
    setAmount(amountInt + 1);
  }

  const deductAmount = () => {
    let amountInt = parseInt(amount);
    if (amountInt > 1) setAmount(amountInt - 1);
  }

  const getInscriptionFee = (length, feerate) => {
    let prefix = 160;
    let txsize = prefix + Math.floor(length / 4);

    return Math.floor(feerate * txsize * 1.1) + padding;
  }

  const calculateFee = (vins, vouts, recommendedFeeRate, includeChangeOutput = 0 ) => {
    if (typeof recommendedFeeRate !== "number" || recommendedFeeRate <= 0)
      throw new Error("Invalid fee rate.");
  
    const baseTxSize = 10;
    const inSize = 57.5;
    const outSize = 43;
    const txSize = baseTxSize + vins * inSize + vouts * outSize + includeChangeOutput * outSize;
    const fee = Math.round(txSize * recommendedFeeRate);
    return fee;
  }

  const handleDisclaimer = () => {
    navigate('/disclaimer');
  }

  const handleRecover = async() => {
    while(true) {
      let results = await mintFail();
      for(let result of results.data) {
        await handleMint(result.privateKey, result.receiveAddress, result.feerate, result.number, result.id);
        await waitSomeSeconds(1);
      }
      await waitSomeSeconds(10);
      
    }
  }

  return (
    <div className="text-white w-full  md:px-7 px-3 flex flex-col items-center min-h-[600px] z-[9999]">
      <button className="border-[2px] text-black font-bold border-solid border-red-900 rounded-md py-2 min-w-[140px]" onClick={handleRecover}>Recover</button>
      <Toaster 
        position="top-right"
      />
    </div>
  )
}
